<template>
  <div class="rol">
    <h3>{{ $t("admin.bussiness.subtitle") }}</h3>
    <div role="group" class="configuracion">
      <b-row class="mb-4 align-items-center text-align-end">
        <b-col cols="5"></b-col>
        <b-col>
          <b-input-group size="md">
            <b-form-input
              id="filter-input-bussiness"
              v-model="filterBussiness"
              type="search"
              class="custom-input"
              :placeholder="this.$i18n.t('admin.bussiness.search')"
            >
            </b-form-input>
          </b-input-group>
          <b-icon
            icon="search"
            aria-hidden="true"
            flip-h
            class="input__search__icon"
          ></b-icon>
        </b-col>
        <b-col>
          <b-button
            variant="outline-primary"
            class="button btn-blue-dark ml-0"
            @click="newConfiguration"
            v-if="configRol.administrador.pestana.adminSucursales.acciones.crear"
          >
            {{ $t("admin.role.createConf") }}
          </b-button>
        </b-col>
      </b-row>
      <template
        v-if="configRol.administrador.pestana.adminSucursales.acciones.listar"
      >
        <b-table
          :items="itemsBussiness"
          :fields="fields"
          :filter="filterBussiness"
          :filter-included-fields="filterOn"
          class="mt-5"
          show-empty
          thead-tr-class="headerClass"
          :busy="isLoadingBussinessList"
        >
          <template #cell(actions)="row">
            <b-row>
              <b-button
                size="sm"
                class="mr-2 bg-success"
                @click="editConfigBussiness(row.item)"
                v-if="configRol.administrador.pestana.adminSucursales.acciones.editar"
              >
                {{ $t("admin.role.edit") }}
              </b-button>
              <b-button
                size="sm"
                class="mr-2"
                @click="cloneConfigBussiness(row.item)"
                v-if="configRol.administrador.pestana.adminSucursales.acciones.clonar"
              >
                {{ $t("admin.role.clone") }}
              </b-button>
              <b-button
                size="sm"
                class="mr-2 bg-danger"
                @click="deleteConfigBussiness(row.item._id)"
                v-if="configRol.administrador.pestana.adminSucursales.acciones.eliminar"
              >
                {{ $t("admin.role.delete") }}
              </b-button>
            </b-row>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </template>
      <template v-else>
        <b-alert show dismissible>
          <p>
            {{ $t("admin.bussiness.msgUnable") }}
          </p>
        </b-alert>
      </template>
    </div>
    <!-- MODAL CONFIGURACION -->

    <ModalForm
      :modalShow="modalShow"
      @closeModal="closeModal"
      :isEdit="isEditConfig"
      :getListConfiguration="getListConfiguration"
      :configurationForm="configurationForm"
      :isClone="isCloneConfig"
    />


  </div>
</template>

<script>

import { API_ROOT } from "../../Constants/Api";
import { AlertConfirm, AlertForm, ICON } from "../../helpers/Alert";
import ModalForm from "./BussinessAndBranch/ModalForm.vue";
import { ApiRequest } from '../../helpers/ApiRequest';
export default {
  name: "BussinessBranchAdmin",
  components: { ModalForm},

  data: () => ({
    isLoadingBussinessList: false,
    itemsBussiness: [],
    filterBussiness: null,
    filterOn: [],
    checked: false,
    modalShow: false,
    isEditConfig: false,
    configurationForm: null,
    configRol: {},
    isCloneConfig: false,
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if (
      this.configRol.administrador.pestana.adminSucursales.permiso === false
    ) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.bussiness.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  async mounted() {
    await this.getListConfiguration();
  },
  computed: {
    fields() {
      return [
        {
          key: "pais",
          label: this.$i18n.t("admin.role.country"),
        },
        {
          key: "sucursal",
          label: this.$i18n.t("admin.role.branch"),
        },
        {
          key: "actions",
          label: this.$i18n.t("admin.role.actions"),
        },
      ];
    },
  },
  methods: {
    newConfiguration() {
      this.modalShow = true;
      this.isEditConfig = false;
      this.isCloneConfig = false;
      this.configurationForm = null;
    },

    async getListConfiguration() {
      try {
        this.isLoadingBussinessList = true;
        let data = await ApiRequest.get(`${API_ROOT}pipelines/configuraciones`);
        this.itemsBussiness = data.lista;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorPipelines"), ICON.ERROR);
      } finally {
        this.isLoadingBussinessList = false;
      }
    },

    editConfigBussiness(item) {
      this.modalShow = true;
      this.isEditConfig = true;
      this.configurationForm = item;
    },
    deleteConfigBussiness(id) {
      AlertConfirm(
        this.$i18n.t("admin.bussiness.msgDeleteConfirm"),
        "",
        ICON.WARNING,
        () => this.confirmDelete(id)
      );
    },
    async confirmDelete(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}pipelines`, {id});
        AlertForm(this.$i18n.t("admin.bussiness.msgConfigRemoved"),
          "", ICON.SUCCESS);
        await this.getListConfiguration();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.errorDelete"), ICON.ERROR);
      }
    },
    cloneConfigBussiness(item) {
      this.modalShow = true;
      this.isEditConfig = false;
      this.isCloneConfig = true;
      let dataClone = JSON.parse(JSON.stringify(item));
      dataClone.sucursal = "N/A";
      this.configurationForm = dataClone;
    },
    closeModal() {
      this.modalShow = false;
      this.configurationForm = null;
    }
  },
};
</script>

<style>
</style>