<template>
  <div>
    <b-modal
      size="xl"
      scrollable
      v-model="modalShow"
      :title="this.$i18n.t('admin.bussiness.formBussiness')"
      no-close-on-backdrop
      hide-header-close
    >

<b-row>
    <b-col cols="4">
      <label class="mt-3 font-weight-bold">{{ $t("navbarInfo.labelsubtipo") }} *</label>
          <template>
            <VueMultiselectComponent
              v-model="selectedSubtipos"
              :options="optionsSubtipo"
            />
          </template>
    </b-col>
  </b-row>
  <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold">{{ $t("admin.role.country") }} *</label>
          <b-form-select
            v-model="form.pais"
            :options="optionsCountry"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || isClone"
          >
            <template #first>
              <b-form-select-option :value="0" disabled
                >-- {{ $t("admin.role.selectCountry") }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold">{{ $t("admin.role.branch") }} *</label>
          <b-form-select
            v-model="form.sucursal"
            :options="optionsSucursal"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || form.pais === 0 "
            v-if="!loadingBranch"
          >
            <template #first>
              <b-form-select-option value="N/A" disabled
                >-- {{ $t("admin.role.selectBranch") }} --</b-form-select-option
              >
            </template>
          </b-form-select>
          <div v-else>
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <hr />
      <!-- CATEGORIAS PRODUCTOS -->
      <h4 class="mt-4">{{ $t("admin.bussiness.showCategory") }}</h4>
      <b-row>
        <b-col cols="4">
          <b-form-select
            v-model="formCategory.categoria"
            :options="optionsCategory"
            size="sm"
            class="mt-0 select"
            :disabled="isEditCategory"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- {{ $t("admin.bussiness.selectCategory") }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="4">
          <b-form-input
            v-model="formCategory.label_category"
            size="sm"
            :placeholder="this.$i18n.t('admin.bussiness.nameCard')"
            lazy
          ></b-form-input>
        </b-col>
        <b-col cols="2">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-model="formCategory.tipocard"
              :aria-describedby="ariaDescribedby"
              name="category-radios"
              value="Multiple"
              :disabled="validateCategory"
              >Multiple</b-form-radio
            >
            <b-form-radio
              v-model="formCategory.tipocard"
              :aria-describedby="ariaDescribedby"
              name="category-radios"
              value="Unico"
              :disabled="validateCategory"
              >{{ $t("admin.bussiness.unique") }}</b-form-radio
            >
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button
            size="sm"
            class="mr-2 bg-success custom-buttom"
            @click="addCategory"
            :disabled="!validateFormCategory"
          >
            <span v-if="!isEditCategory">{{ $t("admin.bussiness.add") }} +</span>
            <span v-else>{{ $t("admin.trm.edit") }} +</span> 
          </b-button>

              <b-icon
                icon="x-circle"
                scale="1.3"
                class="icon_link"
                variant="danger"
                @click="clearCategoryForm"
              ></b-icon>
        </b-col>
        <b-col cols="4">
          <b-form-select
            v-model="pipelineSelected"
            :options="optionsPipeline"
            size="sm"
            class="mt-0 select"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- {{ $t("admin.bussiness.pipelineCreate") }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="4">
          <b-form-select
            v-model="selectStage"
            :options="optionsStage"
            size="sm"
            class="mt-0 select"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- {{ $t("admin.bussiness.pipelineStage") }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      
      <hr />

      <b-table
        :items="itemsCategory"
        :fields="fieldsCategory"
        class="mt-5"
        head-variant="light"
        show-empty
        thead-tr-class="table-category-bussiness"
        tbody-tr-class="table-category-bussiness"
      >
      
        <template #cell(label_category)="data">
          <b>{{ data.value }}</b>
        </template>

        <template #cell(actions)="row">
          <b-row>
            <b-form-checkbox
              v-model="row.item.estado"
              name="check-button"
              switch
            >
            </b-form-checkbox>
          <b-button
            size="sm"
            class="mr-2 bg-success"
            @click="editCategory(row.item)"
          >
             {{ $t("admin.role.edit") }}
          </b-button>
          <b-button
            size="sm"
            class="mr-2 bg-danger"
            @click="deleteCategory(row.item)"
          >

            {{ $t("admin.role.delete") }}
          </b-button>
          </b-row>
          
        </template>
      </b-table>

      <hr />

      <template #modal-footer>
        <b-row>
          <b-col class="justify-content-end buttons-dropdown">
            <b-button
              class="btn btn-blue-dark-outline mr-3"
              @click="handleCloseModal"
            >
              {{ $t("admin.users.cancel") }}
            </b-button>

            <b-button
              class="btn btn-blue-dark ml-0"
              @click="handleConfig"
              :disabled="isLoadingDone || !validateForm"
            >
              <span v-if="!isLoadingDone">
                <span v-if="isEdit">{{ $t("admin.role.edit") }}</span>
                <span v-else>{{ $t("admin.role.create") }}</span>
                <b-icon
                  icon="arrow-right"
                  class="icon ml-2"
                  aria-hidden="true"
                ></b-icon>
              </span>
              <span v-else
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t("admin.medicines.loading") }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>

import { API_HUBSPOT, API_ROOT } from "../../../Constants/Api";
import { AlertConfirm, AlertForm, ICON } from "../../../helpers/Alert";
import { ApiRequest } from '../../../helpers/ApiRequest';
import VueMultiselectComponent from 'vue-multiselect-component'

export default {
  name: "FormBussinessBranchAdmin",
  components: { VueMultiselectComponent},
  props: ["modalShow", "isEdit", "getListConfiguration", "configurationForm", "isClone"],
  data: () => ({
    isLoadingDone: false,
    //form section
    form: {
      pais: 0,
      sucursal: "N/A",
    },
    formCategory: {
      categoria: null,
      label_category: null,
      tipocard: null,
      pipelineID: null,
      estado: false,
      dealstage: null,
      pipeline: "",
      label_stage: "",
      subtype:null
    },

    // options
    optionsSucursal: [],
    optionsCountry: [
      { text: "USA", value: 2 },
      { text: "Mexico", value: 1 },
    ],
    loadingBranch: false,
    // category
    itemsCategory: [],


    allProducts: [],
    optionsCategory: [],
    isEditCategory: false,
    validateCategory: false,

  
    filterProperty: "",
    optionsProperty: [],
    optionsFilterProperty: [],

    hasOptions: false,

    // pipeline

    optionsPipeline: [],
    optionsStage: [],
    pipelineSelected: null,
    optionCategoryPipeline: [],
    itemsPipeline: [],
    selectStage: null,
    subtipoExist:[],
    selectedSubtipos: [],
    optionsSubtipo: [
        { id: '1', label: 'FERTILIDAD' },
        { id: '2', label: 'SUBROGADO' },
        { id: '3', label: 'MORE' },
        { id: '4', label: 'MATERNO' },
        { id: '5', label: 'OVODON' },
        { id: '6', label: 'SEMEN' },
        { id: '7', label: 'GESTANTE' },
        
      ]
  }),
  async mounted() {
    try {
      let data = await ApiRequest.get(`${API_ROOT}pipelines/propsContact`);
      this.optionsProperty = data.data;
      this.optionsFilterProperty = data.data;
    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.errorListPipelines"), ICON.ERROR);
    }

    try {
      let data  = await ApiRequest.get(`${API_ROOT}pipelines/pipelines`);
      this.optionsPipeline = data.datos.map((el) => {
        return {
          text: el.label,
          value: el,
        };
      });
    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.errorListPipelinesTwo"), ICON.ERROR);
    }
  },
  computed: {
    pais() {
      return this.form.pais;
    },
    selectCategory() {
      return this.formCategory.categoria;
    },
    fieldsCategory() {
      return [
        {
          key: "label_category",
          label: this.$i18n.t("admin.bussiness.name"),
        },
        {
          key: "categoria",
          label: this.$i18n.t("admin.bussiness.category"),
        },
        {
          key: "tipocard",
          label: this.$i18n.t("admin.bussiness.typeCard"),
        },    {
          key: "subtype",
          label: `${this.$i18n.t("admin.bussiness.name")} Subtipo`,
        },
         {
          key: "pipeline",
          label: `${this.$i18n.t("admin.bussiness.name")} Pipeline`,
        },
        {
          key: "label_stage",
          label: this.$i18n.t("admin.bussiness.pipelineStage"),
        },
        {
          key: "actions",
          label: this.$i18n.t("admin.bussiness.actions"),
        },
    
      ];
    },

    fieldsPipeline() {
      return [
        {
          key: "pipeline",
          label: `${this.$i18n.t("admin.bussiness.name")} Pipeline`,
        },
        {
          key: "label_stage",
          label: this.$i18n.t("admin.bussiness.pipelineStage"),
        },
        {
          key: "label_category",
          label: this.$i18n.t("admin.bussiness.categoryAssociate"),
        },
        {
          key: "actions",
          label: this.$i18n.t("admin.bussiness.actions"),
        },
      ];
    },
    validateFormCategory() {
      if (
        this.formCategory.categoria && this.formCategory.label_category &&
        this.formCategory.tipocard && this.formCategory.pipelineID && this.formCategory.dealstage
      ) {
        return true;
      }
      return false;
    },

    validateForm() {
      return this.form.pais !== 0 && this.form.sucursal !== "N/A";
    },
  },
  watch: {
    configurationForm() {
      if (this.configurationForm) {
        this.form.sucursal = this.configurationForm.sucursal;
        let dataCategory = [];
        this.configurationForm.configCards.forEach((el) => {
          const subtypesAnt = this.optionsSubtipo.filter(sub => el.subtype.includes(sub.id)).map(sub => sub.label)
          const subtypes = subtypesAnt.map(palabra => palabra.toUpperCase()).join(", ");
          if (!dataCategory.find((item) => el.categoria === item.categoria)) {
            dataCategory.push({
              categoria: el.categoria,
              label_category: el.label_category,
              tipocard: el.tipocard,
              estado: el.estado,
              label_stage: el.label_stage,
              pipeline: el.pipeline,
              pipelineID: el.pipelineID,
              dealstage: el.dealstage,
              subtype: subtypes
            });
          }
        });
        this.form.pais = this.configurationForm.pais === "Us" ? 2 : this.configurationForm.pais === "Mx" ? 1 : 0;
        this.itemsCategory = dataCategory;
        this.optionCategoryPipeline = dataCategory.map(
          (el) => el.label_category
        );
        this.itemsPipeline = this.configurationForm.configCards;
      }
    },

    async pais() {
      if (this.pais) {
        if(!this.isEdit) this.form.sucursal="N/A";
        if (this.isEdit === false && this.isClone === false) {
          this.itemsCategory = [];
        }
        await this.getSucursales(this.pais);
        await this.getProductsXCountry(this.pais);
      }
    },

    selectCategory() {
      if (this.selectCategory) {
          if (["BEC","BEC INTERNACIONAL", "COVERAGE", "TECHNICS","TECNICAS"].includes(this.selectCategory)) {
            this.validateCategory = true;
            this.formCategory.tipocard = "Unico";
          } else {
            this.validateCategory = false;
            if(!this.isEditCategory) this.formCategory.tipocard = null;
          }
      } 
    },
    filterOptions() {
      this.optionsCategoryItem = this.allOptionsCategoryItem.filter((el) =>
        el.text.toLowerCase().includes(this.filterOptions)
      );
    },
    pipelineSelected() {
      if (this.pipelineSelected) {
        this.formCategory.pipeline = this.pipelineSelected.label;
        this.formCategory.pipelineID = this.pipelineSelected.pipelineID;
        this.formCategory.dealstage = null;
        
        this.optionsStage = this.pipelineSelected.etapas.map((el) => {
          return {
            text: el.label,
            value: el,
          };
        });
      }
    },
    filterProperty() {
      this.optionsFilterProperty = this.optionsProperty.filter((el) =>
        el.label.toLowerCase().includes(this.filterProperty)
      );
    },
    selectStage() {
      if(this.selectStage) {
        this.formCategory.dealstage = this.selectStage.stageId;
        this.formCategory.label_stage = this.selectStage.label;
        this.formCategory.estado = this.selectStage.active;
      }
    }
  },

  methods: {
     showModal() {
      this.$bvModal.show('modal-multiple-select');
    },
    limpiarSeleccion() {
      this.selectedSubtipos = [];
    },
    async handleConfig() {
      this.isLoadingDone = true;
      const itemsSubtypes = this.itemsCategory.map(item => {
        const subtypes = item.subtype;

        const idsSubtypes = this.optionsSubtipo
              .filter(sub => subtypes.includes(sub.label))
              .map(sub => sub.id);
        
        return idsSubtypes
      })
      
      this.itemsCategory =  this.itemsCategory.map((item, index) => ({ ...item, subtype: itemsSubtypes[index]}))
      if (this.isEdit) {
        let dataGeneral = {
          id: this.configurationForm._id,
          cards: this.itemsCategory,
          /* reglas_pdf: this.itemsPDF, */
        };

        try {
          await ApiRequest.put(`${API_ROOT}pipelines`, dataGeneral);
          AlertForm(this.$i18n.t("admin.bussiness.bussinessUpdate"),
             "", ICON.SUCCESS, "center");
          
          this.selectedSubtipos = [];
          this.$forceUpdate();
          this.clearForms();
          this.getListConfiguration();
          this.$emit("closeModal");
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.errorUpdateBussiness"), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
        }
      } else {
        let dataGeneral = {
          ...this.form,
          cards: this.itemsCategory,
          /* reglas_pdf: this.itemsPDF, */
        };
        try {
          await ApiRequest.post(`${API_ROOT}pipelines`, dataGeneral);
          AlertForm(this.$i18n.t("admin.bussiness.bussinessCreate"),
            "", ICON.SUCCESS, "center");
          this.clearForms();
          this.getListConfiguration();
          this.$emit("closeModal");
        } catch (err) {
          if (err.status === 500 &&  err.data.msg.includes("duplicate")) {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.duplicatePipeline"), ICON.ERROR);
          } else {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.errorCreateBussiness"), ICON.ERROR);
          }
        } finally {
          this.isLoadingDone = false;
        }
      }
    },

    clearForms() {
      this.itemsCategory = [];
      this.optionsCategory = [];
      this.optionCategoryPipeline = [];
      this.isEditCategory = false;
      this.pipelineSelected = null;
      this.selectStage = null;
      //form section
      this.form = {
        pais: 0,
        sucursal: "N/A",
      };
      this.formCategory = {
        categoria: null,
        label_category: null,
        tipocard: null,
        pipelineID: null,
        estado: false,
        dealstage: null,
        pipeline: "",
        label_stage: "",
        subtype: null,
      };

    },
     async getSuptipos(sucursal, item) {
      this.loadingBranch = true;
      try {
        if (sucursal) {
          let mx = await ApiRequest.get(`${API_ROOT}pipelines/configuracionSucursal/${sucursal}`);
           const card = mx.lista.configCards.find(card => card.categoria === item.categoria);
             const subtypes = card.subtype;
             this.selectedSubtipos=subtypes;
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, 'error al traer subtipos de base', ICON.ERROR);
      } finally {
        this.loadingBranch = false;
      }
    },
    async getSucursales(pais) {
      this.loadingBranch = true;
      try {
        if (pais === 2) {
          let us = await ApiRequest.get(`${API_HUBSPOT}sucursalesUS`);
          this.optionsSucursal = us.sucursales.map((el) => ({
            text: el,
            value: el,
          }));
        } else {
          let mx = await ApiRequest.get(`${API_HUBSPOT}sucursalesMX`);
          this.optionsSucursal = mx.sucursales.map((el) => ({
            text: el,
            value: el,
          }));
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.errorBranch"), ICON.ERROR);
      } finally {
        this.loadingBranch = false;
      }
    },
    async getProductsXCountry(pais) {
      try {
        const data = await ApiRequest.post(`${API_ROOT}cards/productosCategoriaPais`,
          {
            localidad: pais,
          }
        );
        this.allProducts = data.categorias_productos;
        delete this.allProducts["FUNGIBLES - MEDICAMENTOS"];
        delete this.allProducts["PAQUETE MEDICAMENTO"];
        delete this.allProducts["COVERAGE"];
        if (this.isEdit) {
          let optionsInTable = this.itemsCategory.map((el) => el.categoria);
          this.optionsCategory = Object.keys(this.allProducts).filter(
            (el) => !optionsInTable.includes(el)
          );
        } else {
          this.optionsCategory = Object.keys(this.allProducts);
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.errorProducts"), ICON.ERROR);
      }
    },
    addCategory() {
      this.isEditCategory = false;
      this.itemsCategory = this.itemsCategory.filter(
        (el) => el.categoria !== this.formCategory.categoria
      );
      this.optionsCategory = this.optionsCategory.filter(
        (el) => el !== this.formCategory.categoria
      );
      const subtypesAnt = this.optionsSubtipo.filter(sub => this.selectedSubtipos.includes(sub.id)).map(sub => sub.label)
      const subtypes = subtypesAnt.map(palabra => palabra.toUpperCase()).join(", ");
      this.formCategory.subtype = subtypes;

      this.itemsCategory.push(this.formCategory);

      this.formCategory = {
        categoria: null,
        label_category: null,
        tipocard: null,
        pipelineID: null,
        estado: false,
        dealstage: null,
        pipeline: "",
        label_stage: "",
        subtype:null
      };
      this.pipelineSelected = null;
      this.selectStage = null;
      this.selectedSubtipos=[];
    },
    editCategory(item) {
      this.isEditCategory = true;
      const sucursalItem=this.configurationForm.sucursal;
      this.getSuptipos(sucursalItem, item);
      this.optionsCategory.push(item.categoria);
      this.optionCategoryPipeline = this.optionCategoryPipeline.filter(
        (el) => el !== item.label_category
      );
      this.formCategory = JSON.parse(JSON.stringify(item));
      this.pipelineSelected = this.optionsPipeline.find(el => el.text === item.pipeline).value;
      this.selectStage = this.pipelineSelected.etapas.find(el => el.stageId === item.dealstage);
    },
    deleteCategory(item) {
      AlertConfirm(
        this.$i18n.t("admin.bussiness.confirmDelete"),
        this.$i18n.t("admin.bussiness.msgDeletePipeline"),
        ICON.WARNING,
        () => this.confirmDeleteCategory(item)
      );
    },
    confirmDeleteCategory(item) {
      this.itemsCategory = this.itemsCategory.filter(
        (el) => el.categoria !== item.categoria
      );
      this.optionCategoryPipeline = this.optionCategoryPipeline.filter(
        (el) => el !== item.label_category
      );
      this.optionsCategory.push(item.categoria);
      this.itemsPipeline = this.itemsPipeline.filter(
        (el) => el.categoria !== item.categoria
      );
    },

    
    
   
    handleCloseModal() {
      this.clearForms();
      this.$emit("closeModal");
      this.selectedSubtipos=[];
    },
    clearCategoryForm() {
       this.optionsCategory = this.optionsCategory.filter(
        (el) => el !== this.formCategory.categoria
      );
      this.formCategory = {
        categoria: null,
        label_category: null,
        tipocard: null,
        pipelineID: null,
        estado: false,
        dealstage: null,
        pipeline: "",
        label_stage: "",
        subtype:null
      };
      this.pipelineSelected = null;
      this.selectStage = null;
      this.isEditCategory = false;
     

    },

  },
};
</script>

<style scoped>
@import "../../../assets/css/global.css";

.custom-actions {
  cursor: pointer;
}

.custom-card-category, .custom-card-pdf {
  max-height: 250px;
  overflow-y: auto;
}
.custom-card-pdf {
  font-size: 0.8rem;
}
.custom-buttom:disabled {
  cursor: not-allowed;
}

.config-bussiness /deep/ .btn-group {
  width: 100%;
}
.config-bussiness /deep/ .btn-group > button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.config-bussiness /deep/ .dropdown-menu.show {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
}

.groupSearch {
  padding: 0 1rem;
}

.icon_link {
  cursor: pointer;
  opacity: 0.75;
}
/* delete */
.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.rol .configuracion {
  margin-top: 4rem;
}

.rol /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

.modal-config label {
  font-weight: bold;
  margin-top: 1rem;
}
</style>
